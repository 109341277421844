<template>
  <div>
    <!-- 發布到 line notify 下單連結位置 -->
    <div class="border p-3 rounded rounded-5 mb-3">
      <p class="fw-bolder mb-3">發布到 Fb 下單連結位置</p>
      <div class="select-radio">
        <label v-for="data in placeOnOrderUrlPosition" :key="data.id">
          <input
            type="radio"
            name="rows"
            :checked="data.selected"
            @click="
              updateConfiguration('p_fb', 'placeOnOrderUrlPosition', data)
            "
          />
          <span class="radio-style" v-if="data.value === 'd4'">預設</span>
          <span class="radio-style" v-else-if="data.value === 'bottom'"
            >最下面</span
          >
          <span class="radio-style" v-else-if="data.value === 'none'"
            >不放</span
          >
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {}
  },
  created() {
    this.getPrefer()
  },
  computed: {
    placeOnOrderUrlPosition() {
      return this.$store.state.Prefer.p_fb.placeOnOrderUrlPosition
    },
  },
  methods: {
    // 取得偏好
    getPrefer() {
      this.$store.dispatch('Prefer/getStoreInfo', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken')
      })
    },
    // 更新偏好
    updateConfiguration(category, item, data) {
      this.$store.dispatch('Prefer/updateConfiguration', {
        storeId: this.$route.params.storeId,
        serverToken: this.$methods.getCookie('serverToken'),
        category: category,
        item: item,
        data: data
      })
    },
  },
}
</script>